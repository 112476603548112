import { handleErrorWithSentry, replayIntegration, captureConsoleIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { dev, version } from '$app/environment'

if (!dev) {
	Sentry.init({
		dsn: 'https://42a1e0d40bd9e76ea15d1f0429108b85@o4508160482541568.ingest.de.sentry.io/4508160485949520',

		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration({
			mask: ['.masked'],
			maskAllText: false
		}), captureConsoleIntegration({
			levels: ['log', 'warn', 'error']
		})],

		environment: dev ? 'development' : 'production',

		release: version,

		beforeSend(event) {
			if (event.request?.headers?.cookie) event.request.headers.cookie = '[Filtered]'
			return event
		},

		beforeBreadcrumb(breadcrumb) {
			if (breadcrumb.type === 'http' && breadcrumb.data?.cookies)
				breadcrumb.data.cookies = '[Filtered]'

			return breadcrumb
		}
	})
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
